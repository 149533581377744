.cover {
  background-image: url('../../../public/assets/background_v2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: 26vh;
  display: flex;
  align-items: end;
  padding-bottom: 40px;
}

.outer-container {
  min-height: 50vh;
}

.form-alignment {
  margin-left: calc((((100% - (11 * 24px)) / 12) * 1) + (1 * 24px));
  margin-right: calc((((100% - (11 * 24px)) / 12) * 1) + (1 * 24px));
  overflow-wrap: break-word;
}

.title-width {
  width: 83vw;
}

.image-container {
  width: 80vw;
  height: 40vh;
  display: flex;
  justify-content: center;
}

.preview-image {
  max-width: 100%;
  width: 80vw;
  height: 40vh;
  object-fit: contain;
  object-position: left;
}

.form-width {
  width: 80vw;
}

.check-box-text {
  width: 73vw;
  text-align: left;
  display: block;
  font-weight: 500;
  line-height: 120%;
}

.error-message {
  height: 70vh;
}

@media only screen and (min-width: 640px) {
  .check-box-text {
    width: 75vw;
  }
}

@media only screen and (min-width: 768px) {
  .form-width {
    width: 50rem;
  }
  .image-container {
    width: 50rem;
    height: 48vh;
    display: flex;
    justify-content: center;
  }
  .preview-image {
    max-width: 100%;
    width: 50rem;
    height: 48vh;
    object-fit: contain;
    object-position: left;
  }
  .check-box-text {
    width: 48rem;
  }
}

@media only screen and (min-width: 1024px) {
  .title-width {
    width: calc(100% - 42px);
  }

  .form-alignment {
    margin-left: 26px;
    margin-right: 26px;
    overflow-wrap: break-word;
  }

  .error-message {
    height: 30vh;
  }
}

@media only screen and (min-width: 1292px) {
  .title-width {
    width: 1286px;
  }

  .form-alignment {
   margin-left: calc((100% - 1286px) / 2);
   margin-right: calc((100% - 1286px) / 2);
   overflow-wrap: break-word;
  }
}

input[type='checkbox'] {
  visibility: hidden;
}

.check-box-label {
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2.5rem;
}

.check-box {
  display: block;
  height: 15px;
  width: 15px;
  background-color: white;
  border: 1px solid rgb(107 114 128);
}

.check-box-label input:checked ~ .check-box {
  background-color: #ffb81c;
}

.check-box:after {
  content: '';
  position: relative;
  display: none;
}

.check-box-label input:checked ~ .check-box:after {
  display: block;
}

.check-box-label .check-box:after {
  left: 4px;
  bottom: 1px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.envelop {
  width: 18%;
}



.recaptcha-container {
  background-color: #1d1d1b;
  height: 19.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.recaptcha-update {
  color:#ffb81c;
  font-weight: 600;
  font-size: 1.67rem/* 24px */;
  line-height: 2rem/* 32px */;
}

.recaptcha-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.recaptcha-input {
  margin: 1.65rem 2.35rem 1rem 2.35rem;
  padding: 0 0 0 1rem;
  height: 3.75rem;
  width: 82.8vw;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 0;
}

.recaptcha-subcribe {
  height: 4rem;
  width: 82.8vw;
  font-size: 1.17rem;
  line-height: 1.75rem;
  font-style: normal;
  font-weight: 700;
  border-width: 2px;
  border-color: #ffb81c;
  border-radius: 0px;
  color:#ffb81c;
  background-color: #1d1d1b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recaptcha-error{
  color: rgb(239 68 68);
  height: 1rem;
  cursor: default;
}

.recaptcha-message-container{
  background-color: #1d1d1b;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.recaptcha-message{
  color:#ffb81c;
  height: 5rem;
  font-style: normal;
  font-size: medium;  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.grecaptcha-badge { 
  visibility: hidden;
}

@media only screen and (min-width: 768px) {
  .recaptcha-container {
    height: 10.7rem;
    flex-direction: row;
  }

  .recaptcha-input {
    margin: 2rem 2.35rem 1rem 2.35rem;
    width: 17rem;
  }

  .recaptcha-subcribe {
    width: 13.4rem;
  }

  .recaptcha-subcribe:hover {
    background-color: #ffb81c;
    color:white;
  }
}

.submit-button {
  width: 110px;
  height: 40px;
}
